<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">USER GROUP MAINTENANCE</h3>
      <el-button type="primary" style="margin-left: 1rem;" @click="dialogUserGroup = true">Add New User Group</el-button>
    </div>
    <el-dialog title="Add User Group" :visible.sync="dialogUserGroup">
      <el-form ref="usergroupForm" :model="usergroupForm" label-width="120px">
        <el-form-item label="User Group Name">
          <el-input v-model="usergroupForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUserGroup = false">Cancel</el-button>
        <el-button type="primary" @click="addUserGroup">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit User Group" :visible.sync="editUserGroupDialog">
      <el-form ref="editUserGroupForm" :model="editUserGroupForm" label-width="120px">
        <el-form-item label="User Group Name">
          <el-input v-model="editUserGroupForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserGroupDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateUserGroup">Confirm</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border max-height="580">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="GROUP NAME" prop="name"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
        <template slot-scope="props">
          <el-button-group>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editUserGroup(props.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'UserGroup',
  data () {
    return {
      data: [],
      dialogUserGroup: false,
      editUserGroupDialog: false,
      usergroupForm: {
        name: null
      },
      editUserGroupForm: {
        id: null,
        name: null
      }
    }
  },
  mounted () {
    this.getUserGroup()
  },
  methods: {
    getUserGroup () {
      this.$http
        .get('sysadmin.systemaccess/group-list')
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body : []
        })
    },
    addUserGroup () {
      this.dialogUserGroup = false
      this.$http
        .post('sysadmin.systemaccess/group', this.usergroupForm)
        .then(() => {
          this.getUserGroup()
          this.$message({
            message: 'New User Group Added',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    editUserGroup (row) {
      this.editUserGroupDialog = true
      this.editUserGroupForm.id = row.id
      this.editUserGroupForm.name = row.name
    },
    updateUserGroup () {
      this.editUserGroupDialog = false
      this.$http
        .post('sysadmin.systemaccess/group-update', this.editUserGroupForm)
        .then(() => {
          this.getUserGroup()
          this.$message({
            message: 'User Group Updated',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    margin-right: 1rem;
  }
</style>
